import React from 'react';
import FreePoster from '../components/freePoster';
import '../styles/index.scss';


const FreePosterOffer = () => {
  return (
    <FreePoster
      sourceName='group[80976][32]'
      sourceValue='32'
    />
  )
}

export default FreePosterOffer;
